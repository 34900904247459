import React from 'react';
import styles from './TcView.module.css';

export const TcView = () => {
  return (
    <div className={styles.TcView__body}>
      <p>
        <b>TERMS AND CONDITIONS</b>
      </p>
      <br />
      <p>
        The terms, conditions and notices set forth herein (hereinafter the
        “Terms”) are between you and Belmond Management Limited and its
        affiliates (“Belmond”), hotel, train and cruise properties owned,
        operated and/or managed by Belmond (each a “Property” and collectively
        “Belmond Properties”), and Noniussoft – Software e Consultoria Para
        Telecomunicacoes S.A. (“Nonius”) (collectively Belmond and Nonius are
        referred to as the “Providers”).
        <br />
        <br />
        These Terms set forth the scope of your permitted use of the
        “Application” (defined below).
        <br />
        <br />
        The Application is an application for mobile devices that provides you
        convenient access to Belmond Properties’ information, and amenities and
        e-concierge services.
        <br />
        <br />
        <br />
        Please read these Terms carefully since they establish a binding
        agreement between you and the Providers regarding your use of the
        Application and all aspects thereof. Your use of the Application is
        conditioned on your acceptance without modification of these Terms. Your
        acceptance includes the Application as currently downloaded and all
        modifications, upgrades, and new versions that can be accessed without
        acquiring a replacement Application. By clicking “Accept Terms and
        Conditions” below, you acknowledge that you have read, understand and
        agree to be bound by these Terms. If you do not agree to the Terms, do
        not use the Application.
        <br />
        <br />
        There is no fee for use of the Application, but you may incur fees for
        Internet access and roaming charges and for any goods or services
        purchased using the Application. You are financially responsible for all
        fees incurred for Internet access and roaming and for goods or services
        purchased using the Application by you and others accessing the
        Application using your Belmond reservation details (“Belmond Details”).
        <br />
        <br />
        You are solely responsible for maintaining the confidentiality and
        security of your Belmond Details, and for all activities that occur on
        the Application using your Belmond Details, whether or not you know
        about them. You agree to notify Belmond and Belmond Properties
        immediately of any unauthorised disclosure or br/each of your Belmond
        Details. The Providers will not be responsible for any losses arising
        out of the unauthorised use of your Belmond Details.
      </p>
      <br />
      <br />
      <br />
      <p>
        <b>HOW THE APPLICATION WORKS</b>{' '}
      </p>
      <br />
      The Application provides information about Belmond and Belmond Properties
      including photos, videos, descriptions, maps, and directions. The
      Application may also permit you (now or in the future) to find a Belmond
      Property, to make reservations for rooms, dining, spa and certain other
      activities at a Belmond Property, to check-in, check-out, communicate with
      the front desk and concierge staff, view your folio and create and modify
      your profile including billing account information with Belmond and
      Belmond Properties, and at certain Belmond Properties, you may be able to
      make requests for products and services from the Belmond Property during
      your stay (collectively the “Property Services”).
      <br />
      <br />
      <br />
      <p>
        <b>WHO CAN USE THE APPLICATION?</b>
      </p>
      <br />
      You may use the Application only if you are 18 years or older and capable
      of forming a binding contract with the Providers and are not barred from
      using the Application under applicable law. By downloading and using the
      Application, you warrant that you are at least 18 years of age and possess
      the legal authority to enter into these Terms and to use the Application
      in accordance with these Terms.
      <br />
      <br />
      Please note that you are not permitted to download or use the Application
      if: (i) you are located in a country that is subject to a U.S. Government
      or a E.U. Government embargo, or that has been designated by the U.S.
      Government or a E.U. Government as a “terrorist supporting” country; or
      (ii) you are listed on any U.S. Government or E.U. Government list of
      prohibited or restricted parties.
      <br />
      <br />
      By downloading or using the Application you represent and warrant that:
      (i) you are not located in a country that is subject to a U.S. Government
      or a E.U. Government embargo, or that has been designated by the U.S.
      Government or a E.U. Government as a “terrorist supporting” country; and
      (ii) you are not listed on any U.S. Government or E.U. Government list of
      prohibited or restricted parties.
      <br />
      <br />
      You agree to abide by the Terms herein, and as applicable, any third party
      terms, including in connection with any and all purchases you make of
      goods or services (including but not limited to reservations for hotel
      services, where available) or other transactions you enter with the
      Application, including, but not limited to, payment of all amounts when
      due and compliance with all rules and restrictions regarding rates,
      services, products or amenities.
      <br />
      <br />
      <br />
      <p>
        <b>ELECTRONIC CONTRACTING</b>
      </p>
      <br />
      Where the Application enables you to enter into agreements and make
      transactions electronically, you acknowledge that your electronic
      submissions through the Application constitute your agreement and intent
      to be bound by such terms, including cancellation and refund policies, and
      to pay for any related transactions.
      <br />
      <br />
      <br />
      <p>
        <b>CANCELLATION POLICY FOR TRANSACTIONS </b>
      </p>
      <br />
      All standard cancellation policies for rooms and amenities offered by a
      Belmond Property apply to any reservations, orders or purchases of goods
      or services from such Belmond Property made with the Application, where
      applicable. By reserving, ordering or otherwise purchasing goods or
      services with the Application from a Belmond Property, where this is
      available, you agree to be bound by all such Belmond Property’s standard
      cancellation policies and by any other policies that may apply. Failure to
      provide timely cancellation notice for goods or services ordered or
      reserved from a Belmond Property may result in a cancellation penalty
      charged to your room or credit card, at the sole discretion of the
      relevant Belmond Property.
      <br />
      <br />
      <br />
      <p>
        <b>FEEDBACK </b>
      </p>
      <br />
      The Providers welcome feedback, comments and suggestions for improvements
      to the Application (“Feedback”). You can submit Feedback by emailing
      Belmond at reservations.europe@belmond.com You should not include any
      confidential or other personal information or data when submitting
      Feedback.
      <br />
      <br />
      You grant Belmond a non-exclusive, worldwide, perpetual, irrevocable,
      fully-paid, royalty-free, sublicensable and transferable license under any
      and all intellectual property rights that you own or control to use, copy,
      modify, create derivative works based upon and otherwise exploit the
      Feedback, or any ideas, inventions, discoveries or intellectual property
      of any kind disclosed by or embodied in such Feedback for any purpose.
      <br />
      <br />
      <br />
      <p>
        <b>CONTENT AND CONTENT RIGHTS </b>
      </p>
      <br />
      For purposes of these Terms “Content” means text, graphics, images, music,
      software, audio, video, works of authorship of any kind, and information
      or other materials that are posted, generated, provided or otherwise made
      available through the Application.
      <br />
      <br />
      <br />
      <p>
        <b>CONTENT OWNERSHIP, RESPONSIBILITY AND REMOVAL </b>
      </p>
      <br />
      Belmond and its licensors exclusively own all right, title and interest in
      and to the Application and Content, including all associated intellectual
      property rights (with the exception of certain content contained in the
      Application, which may contain third party Content, or links to thirty
      party Content). You acknowledge that the Application and Content are
      protected by copyright, trademark, and other laws of the United States,
      the United Kingdom, the European Union and other countries.
      <br />
      <br />
      <br />
      <p>
        <b>RIGHTS IN CONTENT GRANTED BY PROVIDERS </b>
      </p>
      <br />
      Subject to your compliance with these Terms, Belmond grants you a limited,
      non-exclusive, non-transferable, non-sublicensable license to access and
      use the Content solely for your personal and non-commercial purposes in
      connection with your permitted use of the Application and consistent with
      these Terms.
      <br />
      <br />
      <br />
      <p>
        <b>LICENSE TO THE APPLICATION </b>
      </p>
      <br />
      Subject to your compliance with these Terms, the Providers grant you a
      limited, non-exclusive, non-transferable, non-sublicenseable license to
      download and install a copy of the Application on an unlimited number of
      mobile devices or computers that you own or control, subject to the terms
      and conditions of the marketplace or store from which you receive the
      Application. The Application is for your personal, non-commercial use. The
      Providers reserve all rights in and to the Application not expressly
      granted to you under these Terms.
      <br />
      <br />
      In the event of any third-party claim that the Application or your
      possession and use of the Application infringes such third party’s
      intellectual property rights, the Providers will be solely responsible for
      the investigation, defense, settlement and discharge of any such
      intellectual property infringement claim to the extent required by these
      Terms.
      <br />
      <br />
      You must also comply with all applicable third-party terms of service when
      using the Application.
      <br />
      <br />
      You agree to comply with all U.S., E.U. and other applicable export laws
      and regulations to ensure that neither the Application nor any technical
      data related thereto nor any direct product thereof is exported or
      re-exported directly or indirectly in violation of, or used for any
      purposes prohibited by, such laws and regulations.
      <br />
      <br />
      <br />
      <p>
        <b>GENERAL PROHIBITIONS AND ENFORCEMENT RIGHTS </b>
      </p>
      <br />
      In connection with your use of the Application or Content or exercise of
      any rights under these Terms, and except as provided herein or as
      otherwise agreed to in writing by the Providers, you agree not to do any
      of the following:
      <br />
      <br />
      Post, upload, publish, submit, or transmit any Content;
      <br />
      <br />
      Infringe, misappropriate or violate a third party’s intellectual property
      (including patent, copyright, trademark, service mark, trade secret, moral
      rights or other intellectual property rights, or rights of publicity or
      privacy);
      <br />
      <br />
      Use the Providers’ patents, copyrights, trademarks, service marks, logos,
      trade secrets, moral rights or other intellectual property or proprietary
      information (hereinafter “Providers’ IP”), including but not limited to in
      or as part of meta tags or other hidden text or metadata;
      <br />
      <br />
      Remove, alter or obscure any copyright, trademark, service mark or other
      proprietary rights notices incorporated in or accompanying the Application
      or Content;
      <br />
      <br />
      Infringe, violate or otherwise interfere with or challenge Providers’
      rights in the Application, Content or Providers’ IP;
      <br />
      <br />
      Engage in or encourage any conduct that (i) violates, any applicable law
      or regulation or would give rise to civil liability; (ii) is fraudulent,
      false, misleading or deceptive; (iii) is defamatory, obscene,
      pornographic, vulgar or offensive; (iv) promotes discrimination, bigotry,
      racism, hatred, harassment or harm against any individual or group; (v) is
      violent or threatening or promotes violence or actions that are
      threatening to any person or entity; or (vi) promotes illegal or harmful
      activities or substances;
      <br />
      <br />
      Modify, copy, distribute, transmit, display, perform, reproduce, publish,
      license, sublicense, create derivate works from, transfer, lease, lend,
      rent or sell the Application, any information, software or hardware
      obtained through use of the Application;
      <br />
      <br />
      Display, mirror or frame the Application or any individual element within
      the Application, including but not limited to the layout and design of any
      page or form contained on the Application;
      <br />
      <br />
      Make the functionality of the Application available to multiple users
      through any means;
      <br />
      <br />
      Decipher, decompile, disassemble or reverse engineer any of the software
      used in connection with the Application or Content;
      <br />
      <br />
      Interfere with, or attempt to interfere with, the access of any user, host
      or network, including, without limitation, sending a virus, overloading,
      flooding, spamming, or mail-bombing the Application;
      <br />
      <br />
      Access, tamper with, or use non-public areas of the Application, any
      computer systems, or technical delivery systems related to the Solution;
      <br />
      <br />
      Avoid, bypass, remove, deactivate, impair, descramble or otherwise
      circumvent any technological measure implemented by the Providers or any
      of the Providers’ providers or any other third party (including another
      user) to protect the Application or Content;
      <br />
      <br />
      Attempt to access or search the Application or Content or download Content
      from the Application through the use of any engine, software, tool, agent,
      device or mechanism (including spiders, robots, crawlers, data mining
      tools or the like) other than the software and/or search agents provided
      by Providers or other generally available third-party web br/owsers;
      <br />
      <br />
      Use the Application or Content, or any portion thereof, for any commercial
      purpose or for the benefit of any third party;
      <br />
      <br />
      Use the Application or Content to send altered, deceptive or false
      source-identifying information;
      <br />
      <br />
      Collect or store any personally identifiable information from the
      Application from other users of the Application without their express
      permission;
      <br />
      <br />
      Access, tamper with, or use non-public areas of Providers’ computer
      systems, or the technical delivery systems of the Providers’ providers;
      <br />
      <br />
      Attempt to probe, scan or test the vulnerability of any Providers’ system
      or network or br/each any security or authentication measures;
      <br />
      <br />
      Forge any TCP/IP packet header or any part of the header information in
      any email or newsgroup posting (where applicable);
      <br />
      <br />
      Send any unsolicited or unauthorised advertising, promotional materials,
      email, junk mail, spam, chain letters or other form of solicitation;
      <br />
      <br />
      Impersonate or misrepresent your affiliation with any person or entity;
      <br />
      <br />
      Violate any applicable law or regulation¸ including any U.S. or foreign
      export laws or regulations, including by exporting or re-exporting or
      using for any purpose the Application or any technical data related
      thereto directly or indirectly in violation of or in a manner otherwise
      prohibited by such laws and regulations; or
      <br />
      <br />
      Encourage or enable any other individual to do any of the foregoing.
      <br />
      <br />
      Although the Providers are not obligated to monitor access to or use of
      the Application or Content or to review or edit any Content, the Providers
      have the right to do so for the purpose of operating the Application, to
      ensure compliance with these Terms, and to comply with applicable law or
      other legal requirements. The Providers reserve the right, but are not
      obligated, to remove or disable access to any Content, at any time and
      without notice, including, but not limited to, if Providers, at our sole
      discretion, consider any Content to be objectionable or in violation of
      these Terms. The Providers have the right to investigate violations of
      these Terms or conduct that affects the Application.
      <br />
      <br />
      <br />
      <p>
        <b>COPYRIGHT POLICY</b>
      </p>
      <br />
      The Providers respect copyright law and expect its users to do the same.
      It is Providers’ policy to terminate in appropriate circumstances any
      Application users who repeatedly infringe or are believed to be repeatedly
      infringing the rights of copyright holders.
      <br />
      <br />
      <br />
      <p>
        <b>TERMINATION </b>
      </p>
      <br />
      The Providers reserve the right to modify, suspend, or discontinue the
      Application (or any part or content thereof) or your right to use the
      Application or Content at any time, with or without notice to you, and the
      Providers will not be liable to you or to any third party should Providers
      exercise such rights.
      <br />
      <br />
      Upon any termination, discontinuation or cancellation of the Application,
      the following provisions of these Terms will survive:, Cancellation Policy
      for Transactions, Feedback, Content and Content Rights, Content Ownership,
      Responsibility and Removal, General Prohibitions and Enforcement Rights,
      Copyright Policy, Termination, Data Protection, Disclaimer of Warranties,
      Limitation of Liability and Waiver, Third-Party Sites and Materials,
      Jurisdiction and Applicable Law, and General.
      <br />
      <br />
      <br />
      <p>
        <b>DATA PROTECTION </b>
      </p>
      <br />
      The Personal Data that you provide to us when using the Application will
      be used, processed and stored by Belmond, in accordance with the Belmond
      Privacy Policies. Please refer to the Belmond Privacy Policy at
      https://www.belmond.com/privacy-policy for information on how your
      personal data is collected, used and disclosed. For Application users
      located in the State of California, U.S.A., please also refer to our
      California Privacy Rights policy, at
      https://www.belmond.com/privacy-policy#no4.
      <br />
      <br />
      To this end, Belmond, as controller, will fulfil all of its obligations
      under applicable data protection laws (including the GDPR) with regard to
      the protection of Personal Data.
      <br />
      <br />
      <br />
      Belmond undertakes to preserve the integrity and confidentiality of the
      Personal Data and to put in place the technical and organisational
      measures necessary to protect the Personal Data in accordance with the
      applicable laws. Belmond will take the technical and organisational
      measures required to protect the Personal Data against accidental or
      unauthorised destruction, against accidental loss as well as against
      modification, access and any other unauthorised processing of the Personal
      Data.
      <br />
      <br />
      The Personal Data that you communicate to us through the Application is
      and will remain your exclusive property. You have a right of access,
      rectification, portability and the erasure of your personal data, as well
      as a right to object to and limit processing. These rights may be
      exercised in accordance with the Belmond Privacy Policy.
      <br />
      <br />
      <br />
      <p>
        <b>INDEMNITY </b>
      </p>
      <br />
      You will indemnify and hold harmless the Providers and their officers,
      managers, directors, employees, licensees, contractors, affiliates, and
      agents (collectively the “Provider Parties”), from and against any claims,
      disputes, demands, liabilities, damages, losses, and costs and expenses,
      including, without limitation, reasonable legal and accounting fees
      arising out of or in any way connected with (i) your access to or use of
      the Application, Content or Belmond Details or Feedback, or (ii) your
      violation of these Terms. right to object to and limit processing. These
      rights may be exercised in accordance with the Belmond Privacy Policy.
      <br />
      <br />
      <br />
      <p>
        <b>DISCLAIMER OF WARRANTIES </b>
      </p>
      <br />
      THE APPLICATION IS PROVIDED ON AN “AS IS”, “AS AVAILABLE” AND “WITH ALL
      FAULTS” BASIS. WITHOUT LIMITING THE FOREGOING, THE PROVIDER PARTIES MAKE
      NO REPRESENTATIONS, WARRANTIES OR ENDORSEMENTS OF ANY KIND WHATSOEVER,
      EXPRESS OR IMPLIED, AS TO THE APPLICATION AND THE CONTENT. IN ADDITION,
      THE PROVIDER PARTIES HEREBY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
      INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, FITNESS
      FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE, CUSTOM, TRADE, AND
      QUIET ENJOYMENT.
      <br />
      <br />
      THE PROVIDER PARTIES DO NOT REPRESENT OR WARRANT THAT USE OF THE
      APPLICATION OR CONTENT WILL BE ERROR-FREE OR UNINTERRUPTED; THAT DEFECTS
      WILL BE CORRECTED; OR THAT THE APPLICATION OR THE SERVER THAT MAKES THE
      APPLICATION AVAILABLE IS OR WILL REMAIN FREE FROM ANY HARMFUL COMPONENTS,
      INCLUDING, WITHOUT LIMITATION, VIRUSES. THE PROVIDER PARTIES DO NOT MAKE
      ANY REPRESENTATIONS OR WARRANTIES THAT THE INFORMATION (INCLUDING ANY
      INSTRUCTIONS) ON THE APPLICATION OR CONTENT IS ACCURATE, COMPLETE, OR
      USEFUL. YOU ACKNOWLEDGE THAT YOUR USE OF THE APPLICATION OR CONTENT IS AT
      YOUR SOLE RISK. THE PROVIDER PARTIES DO NOT WARRANT THAT YOUR USE OF THE
      APPLICATION OR CONTENT IS LAWFUL IN ANY PARTICULAR JURISDICTION, AND THE
      PROVIDER PARTIES SPECIFICALLY DISCLAIM SUCH WARRANTIES. SOME JURISDICTIONS
      LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES SO THE
      ABOVE DISCLAIMER MAY NOT APPLY TO YOU TO THE EXTENT SUCH JURISDICTION'S
      LAW IS APPLICABLE TO YOU AND THESE TERMS.
      <br />
      <br />
      <br />
      <p>
        <b>LIMITATION OF LIABILITY AND WAIVER </b>
      </p>
      <br />
      UNDER NO CIRCUMSTANCES WILL THE PROVIDER PARTIES BE LIABLE TO YOU FOR ANY
      LOSS OR DAMAGES OF ANY KIND (INCLUDING, WITHOUT LIMITATION, FOR ANY
      DIRECT, INDIRECT, ECONOMIC, EXEMPLARY, SPECIAL, PUNITIVE, INCIDENTAL OR
      CONSEQUENTIAL LOSSES OR DAMAGES) THAT ARE DIRECTLY OR INDIRECTLY RELATED
      TO: (A) THE APPLICATION; (B) THE CONTENT; (C) YOUR USE OF, INABILITY TO
      USE THE APPLICATION OR CONTENT; (D) THE PERFORMANCE OF THE APPLICATION;
      (E) ANY ACTION TAKEN IN CONNECTION WITH AN INVESTIGATION BY THE PROVIDER
      PARTIES OR LAW ENFORCEMENT AUTHORITIES REGARDING YOUR OR ANY OTHER PARTY'S
      USE OF THE APPLICATION OR CONTENT; (F) ANY ERRORS OR OMISSIONS IN THE
      APPLICATION'S OPERATION; (G) YOUR FEEDBACK; OR (H) ANY DAMAGE TO ANY
      USER'S COMPUTER, MOBILE DEVICE, OR OTHER EQUIPMENT OR TECHNOLOGY
      INCLUDING, WITHOUT LIMITATION, DAMAGE FROM ANY SECURITY BR/EACH OR FROM
      ANY VIRUS, BUGS, TAMPERING, FRAUD, ERROR, OMISSION, INTERRUPTION, DEFECT,
      DELAY IN OPERATION OR TRANSMISSION, COMPUTER LINE OR NETWORK FAILURE OR
      ANY OTHER TECHNICAL OR OTHER MALFUNCTION, INCLUDING, WITHOUT LIMITATION,
      DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, LOSS OF DATA, WORK STOPPAGE,
      ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, EVEN IF
      FORESEEABLE OR EVEN IF THE PROVIDER PARTIES HAVE BEEN ADVISED OF OR SHOULD
      HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER IN AN ACTION OF
      CONTRACT, NEGLIGENCE, STRICT LIABILITY OR TORT (INCLUDING, WITHOUT
      LIMITATION, WHETHER CAUSED IN WHOLE OR IN PART BY NEGLIGENCE, ACTS OF GOD,
      TELECOMMUNICATIONS FAILURE, OR THEFT OR DESTRUCTION OF THE APPLICATION).
      IN NO EVENT WILL THE PROVIDER PARTIES BE LIABLE TO YOU OR ANYONE ELSE FOR
      LOSS, DAMAGE OR INJURY, INCLUDING, WITHOUT LIMITATION, DEATH OR PERSONAL
      INJURY. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
      INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION
      MAY NOT APPLY TO YOU. IN NO EVENT WILL THE PROVIDER PARTIES’ TOTAL
      LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OR ACTION ARISING OUT
      OF OR IN CONNECTION WITH THESE TERMS OR FROM USE OF OR INABILITY TO USE
      THE APPLICATION OR CONTENT EXCEED ONE HUNDRED BR/ITISH POUNDS (£100.00).
      <br />
      <br />
      BELMOND AND BELMOND PROPERTIES’ LIABILITY OR RESPONSIBILITY FOR PRODUCTS
      AND SERVICES PROVIDED BY THE BELMOND PROPERTIES IS GOVERNED BY THE BELMOND
      TERMS AND CONDITIONS APPLICABLE TO THE RELEVANT PRODUCT OR SERVICE, AS SET
      OUT AT HTTPS://WWW.BELMOND.COM/LEGAL/TERMS-AND-CONDITIONS.
      <br />
      <br />
      THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
      ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN THE PROVIDER PARTIES AND YOU.
      <br />
      <br />
      <br />
      <p>
        <b>THIRD-PARTY SITES AND MATERIALS </b>
      </p>
      <br />
      The Application may contain links to web sites and resources operated by
      parties other than the Providers which may provide goods and/or services.
      Where this is the case, such links are provided for your convenience only.
      The Providers do not control such web sites and resources and are not
      responsible for their content or accuracy and the Providers make no
      warranties regarding and will not have any liability or responsibility for
      any third-party websites, for links displayed on such websites or for any
      third parties’ materials, products, or services which are purchased by
      you. The inclusion in the Application of links to such web sites does not
      imply any endorsement or accuracy of the material on such web sites or any
      association with their operators. You acknowledge sole responsibility for
      and assume all risk arising from, your use of any third-party websites or
      resources. Should you have any issues with the products or services
      provided by third parties, you agree that your only recourse is with the
      merchant who provided such products or services and not with the
      Providers.
      <br />
      <br />
      <br />
      <p>
        <b>CHANGES TO THESE TERMS </b>
      </p>
      <br />
      The Providers may, in their sole discretion, change and modify from time
      to time the terms of these Terms or the Application, services, materials,
      programs, policies or other information described herein. If the Providers
      do so, Providers will let you know either by posting the modified Terms on
      any app store or through other communications. It’s important that you
      review the Terms whenever the Providers modify them because if you
      continue to use the Application after the Providers have posted modified
      Terms, you are indicating to the Providers that you agree to be bound by
      the modified Terms. If you do not agree to be bound by the modified Terms,
      then you may not use the Application after such modifications have been
      made. Because the Application may evolve over time, it may be changed or
      discontinued in whole or in part, at any time and without notice to you,
      at our sole discretion.
      <br />
      <br />
      <br />
      <p>
        <b>JURISDICTION AND APPLICABLE LAW </b>
      </p>
      <br />
      Except to the extent that applicable law in your country of residence
      requires application of another law and/or jurisdiction and provides that
      such application of another law and/or jurisdiction cannot be waived or
      altered by contract, this Agreement shall be governed by and construed in
      accordance with the laws of England and Wales, without giving effect to
      any principles of conflicts of law. In any event, this Agreement will not
      be governed by the United Nations Convention on contracts for the
      international sale of goods. Any dispute relating to the validity,
      interpretation, execution, or non-execution of the rights and obligations
      under these Terms shall fall under the exclusive jurisdiction of English
      courts. These Terms constitute the entire agreement between you and the
      Providers regarding the use of the Application and Content. You also may
      be subject to additional terms and conditions that may apply when you use
      affiliate services or purchase goods and services from third party
      providers.
      <br />
      <br />
      <br />
      <p>
        <b>GENERAL </b>
      </p>
      <br />
      No failure or delay on the part of a Provider in exercising any right or
      remedy hereunder or enforcing the terms and conditions of these Terms will
      operate as a waiver thereof. The waiver of any such right or provision
      will be effective only if in writing and signed by a duly authorized
      representative of each Provider. Except as expressly set forth in these
      Terms, the exercise by either party of any of its remedies under these
      Terms will be without prejudice to its other remedies under these Terms or
      otherwise.
      <br />
      <br />
      If any part or provision of these Terms is found to be invalid,
      unenforceable or void, that part or provision will be enforced to the
      maximum extent permissible and the other provisions of these Terms will
      remain in full force and affect.
      <br />
      <br />
      You may not assign or transfer these Terms, by operation of law or
      otherwise, without Providers’ prior written consent. Any attempt by you to
      assign or transfer these Terms, without such consent, will be null.
      Providers may freely assign or transfer these Terms without restriction.
      Subject to the foregoing, these Terms will bind and inure to the benefit
      of the parties, their successors and permitted assigns.
      <br />
      <br />
      Any notices or other communications provided by Providers under these
      Terms, including those regarding modifications to these Terms, will be
      given by posting to the Application. The effective date of any new version
      of the Terms will be clearly posted.
      <br />
      <br />
      <br />
      <p>
        <b>CONTACT INFORMATION </b>
      </p>
      <br />
      If you have any questions about these Terms or the Application, please
      contact Belmond Management Limited at reservations.europe@belmdon.com.
      <br />
      <br />
      Belmond Management Limited
      <br />
      <br />
      Wogan House
      <br />
      <br />
      99 Great Portland Street
      <br />
      <br />
      London
      <br />
      <br />
      W1W 7NY
      <br />
      <br />
      <br />
    </div>
  );
};
