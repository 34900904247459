const getCurrentContent = (
  bookingId,
  productCode,
  rateCode,
  bookingRateContentData = {},
  rateContentData = {}
) => {
  if (bookingId) {
    return bookingRateContentData[productCode]?.[bookingId];
  }
  return rateContentData[productCode]?.[rateCode];
};

export default getCurrentContent;
