import Skeleton from 'react-loading-skeleton';
import { Button, Col, Row } from 'reactstrap';
import { RoomRateDetails } from '../';
import styles from '../../RoomRate.module.css';
import layoutProps from './constants/props';

const DesktopLayout = ({
  name,
  code,
  loading,
  rateContent,
  shortDescription,
  onRateDetailsBtnClick,
  priceTypeMessage,
  numBookingNights,
  t,
  ratePrice,
}) => (
  <Row className="mt-2">
    <Col xs="6" className={styles.RoomRate__roomRate}>
      <div className="fs-5 fw-600">{name}</div>
      {loading ? (
        <div>
          <Skeleton className="my-3" height={25} />
          <Skeleton className="mb-3" height={50} />
        </div>
      ) : (
        <RoomRateDetails
          name={name}
          rateContent={rateContent}
          shortDescription={shortDescription}
        />
      )}
      <div>
        <Button
          className="hoverEffectButton text-nowrap"
          onClick={onRateDetailsBtnClick}
          aria-label={`Open modal for ${name}`}
          id={`${name}-${code}`}
        >
          {t('Rate Details')}
        </Button>
      </div>
    </Col>
    <Col xs="6" className={styles.RoomRate__roomPrice}>
      <div className={styles.RoomRate__roomPriceContainer}>
        <div className="mb-1">
          {t('Total for')} {numBookingNights}{' '}
          {numBookingNights > 1 ? t('nights') : t('night')}
        </div>
        <div className="d-flex align-items-end flex-column">
          {priceTypeMessage.map((m) => (
            <div key={m}>{m}</div>
          ))}
        </div>
        <div className={styles.RoomRate__price} data-testid="RoomRate--price">
          {ratePrice}
        </div>
      </div>
    </Col>
  </Row>
);

DesktopLayout.propTypes = layoutProps;

export default DesktopLayout;
