import PropTypes from 'prop-types';

const layoutProps = {
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  rateContent: PropTypes.object,
  shortDescription: PropTypes.string,
  onRateDetailsBtnClick: PropTypes.func.isRequired,
  priceTypeMessage: PropTypes.arrayOf(PropTypes.string).isRequired,
  numBookingNights: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  ratePrice: PropTypes.string.isRequired,
};

export default layoutProps;
