import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as InfoIcon } from '../../../../assets/images/icons/Info.svg';
import {
  PAYMENT_STATUS_BOOKING_CANCELLED,
  PAYMENT_STATUS_DONE,
  PAYMENT_STATUS_EXPIRED,
  PAYMENT_STATUS_INIT_FAILING,
  PAYMENT_STATUS_JUST_DONE,
} from '../../../../constants';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import bookingConfirmationMessageStyles from '../BookingConfirmationMessage/BookingConfirmationMessage.module.css';

const PaymentMessage = ({
  paymentStatus,
  bookingShown,
  bookingId,
  productCode,
}) => {
  const { t } = useTranslate();
  let title = '';
  let text = '';
  if (paymentStatus === PAYMENT_STATUS_JUST_DONE) {
    title = t('Your booking has been confirmed.');
  } else if (paymentStatus === PAYMENT_STATUS_DONE) {
    title = t('Your booking is already confirmed.');
  } else if (paymentStatus === PAYMENT_STATUS_EXPIRED) {
    title = t('The payment has expired.');
    text = t(
      'If you still have to make the payment, contact us to get a new link.'
    );
  } else if (paymentStatus === PAYMENT_STATUS_BOOKING_CANCELLED) {
    title = t('Your booking has been cancelled.');
  } else if (paymentStatus === PAYMENT_STATUS_INIT_FAILING) {
    title = t('The payment could not be loaded.');
    text = t('Please try again later or contact us to get a new link.');
  } else if (!paymentStatus || bookingShown) {
    return null;
  }

  return (
    <div
      data-testid="failed-bookings-message"
      className={`${bookingConfirmationMessageStyles.BookingConfirmationMessage__rowContainer} d-print-none`}
    >
      <div>
        <div className="d-flex align-items-center">
          <InfoIcon style={{ marginRight: 10, minWidth: 20 }} />
          <div
            className={
              bookingConfirmationMessageStyles.BookingConfirmationMessage__message
            }
            aria-live="polite"
          >
            {!bookingShown && bookingId && (
              <div className="text-uppercase">
                {t('Booking Reference')} {bookingId}-{productCode}
              </div>
            )}
            {title}
            {text && (
              <div
                className={
                  bookingConfirmationMessageStyles.BookingConfirmationMessage__messageEmail
                }
              >
                {text}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PaymentMessage.propTypes = {
  paymentStatus: PropTypes.string,
  bookingShown: PropTypes.bool,
  bookingId: PropTypes.string,
  productCode: PropTypes.string,
};

export default PaymentMessage;
