const shouldFetchNewContent = (
  currentContent,
  params,
  storageKey,
  agentId,
  moment
) => {
  const isFetching = Boolean(localStorage.getItem(storageKey));

  if (isFetching) {
    return false;
  }

  const lastFetched = moment(currentContent?.lastFetched);
  const now = moment();
  const diff = currentContent?.lastFetched
    ? now.diff(lastFetched, 'minutes')
    : 6;

  const lastParams = currentContent?.lastFetchedParams || {};
  const paramsChanged = JSON.stringify(lastParams) !== JSON.stringify(params);
  const isCached =
    currentContent && diff < 5 && agentId === currentContent.agentId;

  return paramsChanged || !isCached;
};

export default shouldFetchNewContent;
