import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SignInForm from '../components/SignInForm/SignInForm';
import SignUpForm from '../components/SignUpForm/SignUpForm';

const MobileLogin = () => {
  const [signInOpen, setSignInOpen] = useState(true);
  const [searchParams] = useSearchParams();

  const emailVerifiedParam = searchParams.get('email_verified');
  const loginRedirectionUrl = `${window.location.origin}/login/callback`;
  const registerRedirectUrl = `${window.location.origin}/sso`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [signInOpen]);

  return (
    <div className="d-flex flex-column mt-4  align-items-center overflow-auto">
      {signInOpen ? (
        <SignInForm
          onError={() => {}}
          onSignUpBtnClick={() => setSignInOpen(false)}
          emailVerified={!!emailVerifiedParam}
          redirectUrl={loginRedirectionUrl}
        />
      ) : (
        <SignUpForm
          onError={() => {}}
          onSignInBtnClick={() => setSignInOpen(true)}
          redirectUrl={registerRedirectUrl}
        />
      )}
    </div>
  );
};

export default MobileLogin;
