import { fetchHotelsRateContent } from '../../../redux/slices/belmondSlice/belmondSlice';

const performFetch = async ({
  isMounted,
  setLoading,
  storageKey,
  params,
  moment,
  axios,
  bookingId,
  dispatch,
  setError,
}) => {
  if (isMounted) setLoading(true);

  try {
    localStorage.setItem(storageKey, 'true');

    const action = fetchHotelsRateContent(params, moment, axios, bookingId);

    if (typeof action === 'function') {
      await action(dispatch);
    } else {
      dispatch(action);
    }
  } catch (e) {
    console.error(e);
    if (isMounted) setError(e);
  } finally {
    localStorage.removeItem(storageKey);
    if (isMounted) setLoading(false);
  }
};

export default performFetch;
