import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/icons/ArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/images/icons/ArrowUp.svg';
import { ReactComponent as CalendarIcon } from '../../assets/images/icons/Calendar.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/icons/Info.svg';
import useLocalisedMoment from '../../hooks/useLocalisedMoment/useLocalisedMoment';
import { useScreenDetector } from '../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import SingleGuestForm from '../SingleGuestForm/SingleGuestForm';
import styles from './MultipleGuestForm.module.css';

const MultipleGuestForm = forwardRef(
  (
    {
      numAdults,
      guestFormIndex,
      onCopyGuestValuesBtnClick,
      booking,
      disabled,
      guestFormsLength,
      defaultExpanded,
    },
    ref
  ) => {
    const { t } = useTranslate();
    const moment = useLocalisedMoment();
    const [formExpanded, setFormExpanded] = useState(defaultExpanded);

    const guestFormKey = `${booking.id}-${booking.productCode}`;
    const { isMobile } = useScreenDetector();

    useImperativeHandle(ref, () => ({
      setFormExpanded,
    }));

    return (
      <fieldset className="mb-4">
        <legend style={{ display: 'none' }}>{t('Guest Information')}</legend>
        <div
          className={styles.MultipleGuestForm__sectionContainer}
          data-testid="MultipleGuestFormDiv"
        >
          <div>
            <div className={styles.MultipleGuestForm__heading}>
              {booking.roomType.title}
              <div className={styles.MultipleGuestForm__roomNumerator}>
                {t('Room')} {guestFormIndex + 1} {t('of')} {guestFormsLength}
              </div>
            </div>

            <div
              className="d-flex w-100 align-items-center"
              style={{ flexDirection: isMobile ? 'column' : 'row' }}
            >
              <Row
                className="d-flex align-items-center w-100 gap-3"
                style={{ marginBottom: isMobile ? 20 : 0 }}
              >
                <Col md="5" className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-1">
                    <InfoIcon style={{ marginBottom: 3 }} />
                    <span className={styles.MultipleGuestForm__boldText}>
                      {t('Rate')}
                    </span>
                  </div>
                  <span>{parse(booking.roomRate.name)}</span>
                </Col>
                <Col md="3" className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-1">
                    <CalendarIcon style={{ marginBottom: 3 }} />
                    <span className={styles.MultipleGuestForm__boldText}>
                      {t('Arrival')}
                    </span>
                  </div>
                  <span>
                    {moment(new Date(booking.startDate)).format('DD MMM YY')}
                  </span>
                </Col>

                <Col md="3" className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-1">
                    <CalendarIcon style={{ marginBottom: 3 }} />
                    <span className={styles.MultipleGuestForm__boldText}>
                      {t('Departure')}
                    </span>
                  </div>
                  <span>
                    {moment(new Date(booking.endDate)).format('DD MMM YY')}
                  </span>
                </Col>
              </Row>
              <Button
                style={{ minWidth: 181 }} // prevent button from shrinking when text changes
                data-testid={`MultipleGuestForm--ShowDetails-${guestFormIndex}`}
                className="button transparent"
                onClick={() => setFormExpanded((prev) => !prev)}
              >
                {formExpanded ? (
                  <div className="d-flex align-items-center justify-content-between">
                    {t('Hide Details')}
                    <ArrowUpIcon style={{ marginLeft: 10 }} />
                  </div>
                ) : (
                  <div>
                    {t('Show Details')}
                    <ArrowDownIcon style={{ marginLeft: 10 }} />
                  </div>
                )}
              </Button>
            </div>
          </div>
          <div
            className={
              formExpanded ? '' : `${styles.MultipleGuestForm__collapse}`
            }
            data-testid="guestFormContainer"
          >
            <hr className={styles.MultipleGuestForm__dashedHr} />

            <SingleGuestForm
              guestFormKey={guestFormKey}
              numAdults={numAdults}
              disabled={disabled}
            />
            {onCopyGuestValuesBtnClick && (
              <div>
                <Button
                  className="hoverEffectButton"
                  onClick={onCopyGuestValuesBtnClick}
                >
                  <div style={{ overflow: 'hidden' }}>
                    {t('Apply the above information to all rooms')}
                  </div>
                </Button>
              </div>
            )}
          </div>
        </div>
      </fieldset>
    );
  }
);
MultipleGuestForm.propTypes = {
  onCopyGuestValuesBtnClick: PropTypes.func,
  booking: PropTypes.object,
  disabled: PropTypes.bool,
  guestFormsLength: PropTypes.number,
  numAdults: PropTypes.number,
  guestFormIndex: PropTypes.number,
  defaultExpanded: PropTypes.bool,
};

export default MultipleGuestForm;
