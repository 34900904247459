import PropTypes from 'prop-types';
import { ReactComponent as GreenCheckIcon } from '../../../../../../assets/images/icons/greenCheck.svg';
import { ReactComponent as RedCancelCrossIcon } from '../../../../../../assets/images/icons/redCancelCross.svg';
import useTranslate from '../../../../../../hooks/useTranslate/useTranslate';

const CalendarFeedback = ({ disabledPickedDateReason, isSpecialCode }) => {
  const { t } = useTranslate();
  return (
    <div className="mx-5 mt-3 pb-4">
      {isSpecialCode && (
        <div className="d-flex align-items-center mx-1">
          <GreenCheckIcon className="flex-shrink-0" />
          <div className="mx-2">{t('Offer Applied!')}</div>
        </div>
      )}
      {disabledPickedDateReason && (
        <div className="my-3 d-flex align-items-center">
          <RedCancelCrossIcon className="flex-shrink-0" />
          <div className="mx-2">{disabledPickedDateReason}</div>
        </div>
      )}
    </div>
  );
};
CalendarFeedback.propTypes = {
  disabledPickedDateReason: PropTypes.string,
  isSpecialCode: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  multiRooms: PropTypes.bool,
};
export default CalendarFeedback;
