import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { MY_ACCOUNT_DOMAIN } from '../../globals';
import { logoutWithoutRedirect } from '../../hooks/useCheckReach5Session/helpers';
import { useScreenDetector } from '../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import { resetBookingsState } from '../../redux/slices/bookingsSlice/bookingsSlice';
import { resetGuestFormState } from '../../redux/slices/guestFormSlice/guestFormSlice';
import { resetPaymentState } from '../../redux/slices/paymentSlice/paymentSlice';
import { logout } from '../../redux/slices/userSlice/userSlice';
import GuestLoginDesktop from './GuestLoginDesktop';
import GuestLoginMobile from './GuestLoginMobile';

const GuestLogin = ({ loading, productCode, onLogin }) => {
  const { t } = useTranslate();
  const profileButtons = (
    <>
      <Button
        className="textButton text-uppercase"
        aria-label="Link to My Stays"
        onClick={() =>
          window.open(`${MY_ACCOUNT_DOMAIN}/account/profile/bookings`, '_blank')
        }
        tabIndex={0}
      >
        {t('My Stays')}
      </Button>
      <Button
        className="textButton text-uppercase"
        aria-label="Link to My Profile"
        onClick={() =>
          window.open(
            `${MY_ACCOUNT_DOMAIN}/account/profile/my-profile`,
            '_blank'
          )
        }
        tabIndex={0}
      >
        {t('My Profile')}
      </Button>
    </>
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLargeDesktop } = useScreenDetector();

  const userProfile = useSelector((state) => state.user.profile);
  const accessToken = useSelector((state) => state.user.accessToken);
  const loggedIn = !!accessToken;
  const refreshToken = useSelector((state) => state.user.refreshToken);

  const handleGuestLoginLogOut = async () => {
    try {
      await logoutWithoutRedirect(refreshToken);
      if (productCode) {
        navigate(`/select-room?productCode=${productCode}`);
      } else {
        navigate('/');
      }
      dispatch(resetGuestFormState());
      dispatch(resetBookingsState());
      dispatch(resetPaymentState());
      dispatch(logout());
    } catch (e) {
      // console.log(e); // TODO: handle the error somwhow
    }
  };

  const logoutButton = (
    <Button
      className="textButton text-uppercase"
      aria-label="Link for Logout"
      onClick={handleGuestLoginLogOut}
    >
      {t('Log out')}
    </Button>
  );

  return (
    <div className="w-100">
      {isLargeDesktop ? (
        <div className="d-flex">
          <GuestLoginDesktop
            loading={loading}
            loggedIn={loggedIn}
            firstName={userProfile?.firstName}
            profileButtons={profileButtons}
            logoutButton={logoutButton}
            onLogin={onLogin}
          />
        </div>
      ) : (
        <div className="d-flex flex-column w-100">
          <GuestLoginMobile
            loading={loading}
            loggedIn={loggedIn}
            firstName={userProfile?.firstName}
            profileButtons={profileButtons}
            logoutButton={logoutButton}
            onLogin={onLogin}
          />
        </div>
      )}
    </div>
  );
};
GuestLogin.propTypes = {
  loading: PropTypes.bool,
  productCode: PropTypes.string,
  onLogin: PropTypes.func,
};

export default GuestLogin;
