import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { ReactComponent as CalendarIcon } from '../../../../assets/images/icons/Calendar.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/images/icons/Info.svg';
import { ReactComponent as ProfileIcon } from '../../../../assets/images/icons/Profile.svg';
import formatRoomName from '../../../../helpers/formatRoomName/formatRoomName';
import useLocalisedMoment from '../../../../hooks/useLocalisedMoment/useLocalisedMoment';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import styles from './BookingOverviewModal.module.css';

const BookingOverview = ({
  booking,
  media,
  numOfBookings,
  bookingIndex,
  onEditRoom,
}) => {
  const { t } = useTranslate();
  const moment = useLocalisedMoment();
  const bookingImage = media.filter((mediaItem) =>
    mediaItem.includes(
      `RoomImages/${booking.productCode}/${booking.roomType.code}`
    )
  )[0];

  const childrenText =
    booking.children?.length > 1 ? t('children') : t('child');
  const adultsText = booking.numAdults > 1 ? t('adults') : t('adult');

  return (
    <Row className={styles.BookingOverviewModal__bookingRow} key={booking.id}>
      <Col md="3">
        <img
          style={{
            width: '100%',
            height: '187px',
            objectFit: 'cover',
          }}
          src={bookingImage}
          alt={booking.roomType.code}
        />
      </Col>
      <Col md="9" className={styles.BookingOverviewModal__bookingInfo}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className={styles.BookingOverviewModal__roomTitle}>
            {formatRoomName(booking.roomType.category.name).toUpperCase()}{' '}
            {booking.roomType.title}
          </div>
          <div className={styles.BookingOverviewModal__roomCount}>
            Room {bookingIndex + 1} of {numOfBookings}
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="bold d-flex align-items-center">
            <CalendarIcon style={{ marginRight: 15 }} />
            {moment(booking.startDate).format('ddd, MMM DD, YYYY')} -{' '}
            {moment(booking.endDate).format('MMM DD, YYYY')}
          </div>

          <div className="mt-4 mb-2 d-flex align-items-center">
            <ProfileIcon style={{ marginRight: 16 }} />
            {booking.numAdults} {adultsText}
            {booking.children?.length > 0 &&
              `, ${booking.children?.length} ${childrenText}`}
          </div>
          <Row className="d-flex align-items-center justify-content-between my-0 py-0">
            <Col md="8" className="d-flex align-items-center my-3">
              <InfoIcon style={{ marginRight: 12, flexShrink: 0 }} />
              <div className="bold">Rate: </div> &nbsp;
              {parse(booking.roomRate.name)}
            </Col>
            <Col md="4" className="d-flex justify-content-end">
              <Button
                className="button transparent"
                onClick={() => onEditRoom(bookingIndex)}
              >
                <div className="mx-3">Edit</div>
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

BookingOverview.propTypes = {
  booking: PropTypes.object,
  media: PropTypes.array,
  numOfBookings: PropTypes.number,
  bookingIndex: PropTypes.number,
  onEditRoom: PropTypes.func,
};

export default BookingOverview;
