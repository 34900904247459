export const pollFor3DSIframe = (paymentContainer, maxAttempts, interval) => {
  return new Promise((resolve, reject) => {
    let attempts = 0;

    const checkForIframe = () => {
      if (!paymentContainer.current) {
        return;
      }

      const iframe = paymentContainer.current.querySelector(
        'iframe[name="threeDSIframe"]'
      );

      if (iframe) {
        resolve(iframe);
        return;
      }

      attempts++;
      if (attempts >= maxAttempts) {
        reject(new Error('Timeout waiting for 3DS'));
        return;
      }

      setTimeout(checkForIframe, interval);
    };

    checkForIframe();
  });
};

export default pollFor3DSIframe;
