const MobileLoginSuccess = () => {
  return (
    <div
      style={{ height: '100vh' }}
      className="w-100 d-flex align-items-center justify-content-center"
    >
      Login Successful - please wait for redirection!
    </div>
  );
};

export default MobileLoginSuccess;
