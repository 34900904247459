import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import client from '../../helpers/authClient';
import useMessage from '../../hooks/useMessage/useMessage';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import LoadingButton from '../LoadingButton/LoadingButton';
import styles from './SignUpForm.module.css';

const SignUpEmailVerificationMessage = ({
  accessToken,
  onBack,
  redirectUrl,
}) => {
  const [Message, setMessage, closeMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslate();

  const handleSendEmailVerificationBtnClick = async () => {
    closeMessage();
    setLoading(true);
    try {
      await client.sendEmailVerification({
        accessToken: accessToken,
        redirectUrl: redirectUrl,
      });
      setMessage(
        'Email succesfuly sent! Please check your email for verification.',
        'success'
      );
    } catch (e) {
      setMessage(e.errorUserMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.SignUpForm__container}>
      <div className={styles.SignUpForm__header}>
        {t('WELCOME TO OUR WONDERFUL WORLD')}
      </div>
      <hr className={styles.SignUpForm__line} />
      <div className="d-flex justify-content-start w-100 mt-2">
        <Button
          className="textButton"
          style={{
            borderBottom: '1px solid black',
            fontWeight: '500',
            lineHeight: '15px',
          }}
          onClick={onBack}
        >
          {t('Back')}
        </Button>
      </div>

      <div className="my-2">
        {t('Thank you for signing up for an account!')}
      </div>
      <div className="text-center my-2">
        {t(
          'Please check your inbox for the verification email and click the link provided.'
        )}{' '}
        {t(
          'If you do not receive a message within a few minutes, please check your Spam folder.'
        )}
      </div>
      <div className="text-center mb-4">
        {t(
          'If you have not received this email, please click the button below to re-send the email.'
        )}
      </div>
      <Message />
      <LoadingButton
        className="button"
        ariaLabel="Confirm Email"
        onClick={handleSendEmailVerificationBtnClick}
        loading={loading}
      >
        {t('Re-send email')}
      </LoadingButton>
    </div>
  );
};
SignUpEmailVerificationMessage.propTypes = {
  accessToken: PropTypes.string,
  onBack: PropTypes.func,
  redirectUrl: PropTypes.string,
};
export default SignUpEmailVerificationMessage;
