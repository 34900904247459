import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form } from 'reactstrap';
import { gtmNewsletterSubscription } from '../../gtm/events';
import client from '../../helpers/authClient';
import { logoutWithoutRedirect } from '../../hooks/useCheckReach5Session/helpers';
import useMessage from '../../hooks/useMessage/useMessage';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import InputComponent from '../InputComponent/InputComponent';
import LoadingButton from '../LoadingButton/LoadingButton';
import PasswordInfo from '../PasswordInfo/PasswordInfo';
import SocialLoginButtons from '../SocialLoginButtons/SocialLoginButtons';
import SignUpEmailVerificationMessage from './SignUpEmailVerificationMessage';
import styles from './SignUpForm.module.css';
import SignUpFormConsents from './SignUpFormConsents';

const SignUpForm = ({ onSignInBtnClick, onError, redirectUrl }) => {
  const {
    t,
    constants: {
      errorMessage: failErrorMessage,
      formRules: { emailRule },
    },
  } = useTranslate();

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    mode: 'all',
  });
  const [loading, setLoading] = useState(false);
  const [Message, setMessage] = useMessage();
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const [userAccessToken, setUserAccessToken] = useState(null);

  const errorMessage = (msg) => {
    if (msg.error === 'email_already_exists') {
      return `${t('Email already in use.')} ${t('Please, try logging in.')}`;
    }
    if (msg.errorMessageKey === 'error.password.policy') {
      return (
        <div>
          <div>{msg.errorUserMsg}:</div>
          {msg.errorDetails.map((e) => (
            <div className="mb-2" key={e.message}>
              {e.message}
            </div>
          ))}
        </div>
      );
    }
    return msg.errorUserMsg;
  };

  const handleFormSubmit = async ({
    signUpEmail,
    signUpPassword,
    signUpFirstName,
    signUpLastName,
    marketingConsent,
    profilingConsent,
  }) => {
    setLoading(true);
    try {
      const res = await client.signup({
        data: {
          email: signUpEmail,
          password: signUpPassword,
          givenName: signUpFirstName,
          familyName: signUpLastName,
          consents: {
            marketing_consent: { granted: marketingConsent || false },
            profiling_consent: { granted: profilingConsent || false },
          },
        },
        redirectUrl,
        auth: {
          useWebMessage: true,
          prompt: 'login',
          redirectUri: redirectUrl,
        },
      });

      if (marketingConsent) {
        gtmNewsletterSubscription('Account');
      }

      await logoutWithoutRedirect();
      setUserAccessToken(res.accessToken);
      setEmailVerificationSent(true);
    } catch (e) {
      setMessage(errorMessage(e) || failErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleSocialButtonsError = (errMessage) => {
    setMessage(errMessage);
    setLoading(false);
    onError();
  };

  const handleBackBtnClick = () => {
    setEmailVerificationSent(false);
    reset();
  };

  if (emailVerificationSent) {
    return (
      <SignUpEmailVerificationMessage
        accessToken={userAccessToken}
        onBack={handleBackBtnClick}
        redirectUrl={redirectUrl}
      />
    );
  }

  return (
    <div className={styles.SignUpForm__container}>
      <div className={styles.SignUpForm__header}>{t('Sign Up')}</div>
      <hr className={styles.SignUpForm__line} />
      <div className="d-flex justify-content-start w-100 mt-2">
        <Button
          className="textButton"
          style={{
            borderBottom: '1px solid black',
            fontWeight: '500',
            lineHeight: '15px',
          }}
          onClick={onSignInBtnClick}
        >
          {t('Back')}
        </Button>
      </div>
      <SocialLoginButtons
        onError={handleSocialButtonsError}
        onButtonsClick={() => setLoading(true)}
        redirectToSuccess={!!redirectUrl}
      />
      <div className={styles.SignUpForm__dottedLine}></div>
      <div style={{ fontWeight: 500, textTransform: 'uppercase' }}>
        {t('or')}
      </div>
      <Form
        className="w-100"
        onSubmit={handleSubmit(handleFormSubmit)}
        noValidate
      >
        <label className={styles.SignUpForm__label} htmlFor="signUpFirstNameID">
          {t('First Name')}*
        </label>

        <InputComponent
          name="signUpFirstName"
          control={control}
          rules={{ required: 'First name is required' }}
          errors={errors}
          autoComplete="given-name"
          inputClassName={styles.SignUpForm__input}
        />
        <label className={styles.SignUpForm__label} htmlFor="signUpLastNameID">
          {t('Last Name')}*
        </label>
        <InputComponent
          name="signUpLastName"
          control={control}
          rules={{ required: 'Last name is required' }}
          errors={errors}
          autoComplete="last-name"
          inputClassName={styles.SignUpForm__input}
        />
        <label className={styles.SignUpForm__label} htmlFor="signUpEmailID">
          {t('Email Address')}*
        </label>
        <InputComponent
          name="signUpEmail"
          control={control}
          rules={emailRule}
          errors={errors}
          autoComplete="email"
          inputClassName={styles.SignUpForm__input}
        />
        <label className={styles.SignUpForm__label} htmlFor="signUpPasswordID">
          <div className="d-flex align-items-center">
            {t('Password')}*
            <PasswordInfo />
          </div>
        </label>

        <InputComponent
          name="signUpPassword"
          control={control}
          type="password"
          rules={{ required: 'Password is required' }}
          errors={errors}
          autoComplete="new-password"
          inputClassName={styles.SignUpForm__input}
        />
        <label
          className={styles.SignUpForm__label}
          htmlFor="signUpRepeatPasswordID"
        >
          {t('Confirm Password')}*
        </label>

        <InputComponent
          name="signUpRepeatPassword"
          control={control}
          type="password"
          rules={{
            validate: (value) =>
              value === getValues('signUpPassword') ||
              t('The passwords do not match'),
          }}
          errors={errors}
          autoComplete="new-password"
          inputClassName={styles.SignUpForm__input}
        />

        <Message className="d-flex flex-column" />
        <SignUpFormConsents formControl={control} />

        <div className="text-center my-4">
          <LoadingButton
            className="button"
            type="submit"
            ariaLabel="Sign Up"
            loading={loading}
          >
            {t('Sign Up')}
          </LoadingButton>
        </div>
      </Form>
      <div className="d-flex flex-column m-3">
        {t('Already have an account?')}
        <div className="text-center my-3">
          <Button
            onClick={onSignInBtnClick}
            className="button transparent transparent-border"
            aria-label="Go to Log In"
          >
            {t('Log In')}
          </Button>
        </div>
      </div>
    </div>
  );
};
SignUpForm.propTypes = {
  onSignInBtnClick: PropTypes.func,
  onError: PropTypes.func,
  redirectUrl: PropTypes.string,
};

export default SignUpForm;
