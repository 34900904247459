import { datadogRum } from '@datadog/browser-rum';
import { CHINA_VERSION, env } from './globals';
import checkAllowedDatadogErrors from './helpers/check-allowed-datadog-errors';

if (env !== 'local' && !CHINA_VERSION) {
  datadogRum.init({
    applicationId: 'a6c4908d-eb55-4583-a350-d293e51a71d4',
    clientToken: 'pubd37e00a617394fcbb12949545834cf80',
    site: 'datadoghq.eu',
    service: 'bbe',
    env,
    version: process.env.REACT_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: env === 'prod' ? 20 : 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: (event) => {
      const allowed = checkAllowedDatadogErrors(event);
      return !allowed;
    },
  });
}
