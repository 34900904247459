import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CURRENCY_SYMBOLS } from '../../../../../../constants';
import useTranslate from '../../../../../../hooks/useTranslate/useTranslate';
import CalendarFeedback from '../CalendarFeedback/CalendarFeedback';
import styles from './CalendarLegend.module.css';

const CalendarLegend = ({ disabledPickedDateReason, isSpecialCode }) => {
  const { t } = useTranslate();
  const currencyCode = useSelector(
    (state) => state.appSettings.currencies.current
  );

  const { getValues } = useFormContext();

  const multiRooms = getValues('guestsPerRoom').length > 1;
  const currencyData = CURRENCY_SYMBOLS[currencyCode];

  // Check if the current currency symbol appears multiple times
  const isSymbolDuplicated =
    Object.values(CURRENCY_SYMBOLS).filter(
      (currency) => currency.symbol_native === currencyData.symbol_native
    ).length > 1;

  const displayCurrencyLegend = isSymbolDuplicated && !multiRooms;

  return (
    <div>
      <div className={styles.CalendarLegend__container}>
        {displayCurrencyLegend && (
          <div className="d-flex align-items-center">
            <div className={styles.CalendarLegend__currency}>
              {currencyData.symbol_native}
            </div>
            {t('Prices shown in')} {currencyData.name}
          </div>
        )}

        <div className="d-flex align-items-center">
          <div className={styles.CalendarLegend__unavailable}></div>
          {t('Please contact the hotel directly for assistance.')}
        </div>
      </div>

      <CalendarFeedback
        disabledPickedDateReason={disabledPickedDateReason}
        isSpecialCode={isSpecialCode}
      />
    </div>
  );
};

CalendarLegend.propTypes = {
  disabledPickedDateReason: PropTypes.string,
  isSpecialCode: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default CalendarLegend;
