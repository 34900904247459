const createFetchParams = ({
  rateCode,
  startDate,
  endDate,
  numAdults,
  productCode,
  agentId,
  currentLanguage,
}) => ({
  rateCode,
  startDate,
  endDate,
  numAdults,
  productCode,
  agentId,
  lang: currentLanguage,
});

export default createFetchParams;
