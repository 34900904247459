import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import Contact from '../Contact/Contact';
import Copyright from '../Copyright/Copyright';
import ExploreOurWorldList from '../ExploreOurWorld/ExploreOurWorldList';
import JoinOurFamilyList from '../JoinOurFamilyList/JoinOurFamilyList';
import Newsletter from '../Newsletter/Newsletter';
import SocialIcons from '../SocialIcons/SocialIcons';
import styles from './FooterMobile.module.css';

const FooterMobile = ({
  loading,
  instagramLink,
  facebookLink,
  address,
  phone,
  contactUsLink,
  productCode,
  findUsLink,
}) => {
  const { t } = useTranslate();
  const [contactCollapsed, setContactCollapsed] = useState(false);
  const [worldCollapsed, setWorldCollapsed] = useState(false);
  const [familyCollapsed, setFamilyCollapsed] = useState(false);
  const [termsCollapsed, setTermsCollapsed] = useState(false);
  const [newsletterSubmitted, setNewsletterSubmitted] = useState(false);

  const newsletterEnabled = useSelector(
    (state) => state.appSettings.featureFlags.newsletterEnabled
  );

  const handleNewsletterSubmitted = () => {
    setNewsletterSubmitted(true);
  };

  return (
    <div className="container-xxl py-4" data-testid="Footer--mobile">
      <div className={`${styles.Footer__bbeFooter} mt-5 mx-2`}>
        <div className={styles.FooterMobile__section}>
          <button
            onClick={() => setContactCollapsed((prev) => !prev)}
            className={styles.FooterMobile__sectionSelector}
          >
            {t('Contact Us')}
            <span
              className={`${
                contactCollapsed
                  ? `${styles.FooterMobile__arrowUp}`
                  : `${styles.FooterMobile__arrowDown}`
              }`}
              data-testid="contactArrow"
            ></span>
          </button>
          {contactCollapsed && (
            <div className={styles.FooterMobile__sectionList}>
              <Contact
                loading={loading}
                address={address}
                phone={phone}
                contactUsLink={contactUsLink}
                productCode={productCode}
              />
            </div>
          )}
        </div>
        <div className={styles.FooterMobile__section}>
          <button
            onClick={() => setWorldCollapsed((prev) => !prev)}
            className={styles.FooterMobile__sectionSelector}
          >
            {t('Explore Our World')}
            <span
              className={`${
                worldCollapsed
                  ? `${styles.FooterMobile__arrowUp}`
                  : `${styles.FooterMobile__arrowDown}`
              }`}
              data-testid="exploreArrow"
            ></span>
          </button>
          {worldCollapsed && (
            <div className={styles.FooterMobile__sectionList}>
              <ExploreOurWorldList
                findUsLink={findUsLink}
                contactUsLink={contactUsLink}
              />
            </div>
          )}
        </div>
        <div className={styles.FooterMobile__section}>
          <button
            onClick={() => setFamilyCollapsed((prev) => !prev)}
            className={styles.FooterMobile__sectionSelector}
          >
            {t('Join Our Family')}
            <span
              className={`${
                familyCollapsed
                  ? `${styles.FooterMobile__arrowUp}`
                  : `${styles.FooterMobile__arrowDown}`
              }`}
              data-testid="familyArrow"
            ></span>
          </button>
          {familyCollapsed && (
            <div className={styles.FooterMobile__sectionList}>
              <JoinOurFamilyList />
            </div>
          )}
        </div>
        <div className={styles.FooterMobile__section}>
          <button
            onClick={() => setTermsCollapsed((prev) => !prev)}
            className={styles.FooterMobile__sectionSelector}
          >
            {t('Terms')}
            <span
              className={`${
                termsCollapsed
                  ? `${styles.FooterMobile__arrowUp}`
                  : `${styles.FooterMobile__arrowDown}`
              }`}
              data-testid="termsArrow"
            ></span>
          </button>

          {termsCollapsed && (
            <div className="mb-5">
              <div className={styles.FooterMobile__sectionList}>
                <Copyright />
              </div>
            </div>
          )}
        </div>

        <div className={styles.FooterMobile__socialIcons}>
          <SocialIcons
            instagramLink={instagramLink}
            facebookLink={facebookLink}
          />
        </div>

        {newsletterEnabled && (
          <div className={styles.FooterMobile__section}>
            {!newsletterSubmitted ? (
              <>
                <h5>
                  SIGN UP FOR EXCLUSIVE NEWS, TRAVEL INSPIRATION AND OFFERS
                </h5>
                <Newsletter onNewsletterSubmitted={handleNewsletterSubmitted} />
              </>
            ) : (
              <p>
                Thank you for signing up. You're all set to receive the latest
                news and offers from Belmond.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

FooterMobile.propTypes = {
  loading: PropTypes.bool,
  instagramLink: PropTypes.string.isRequired,
  facebookLink: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  contactUsLink: PropTypes.string.isRequired,
  productCode: PropTypes.string,
  findUsLink: PropTypes.string.isRequired,
};

export default FooterMobile;
