import { datadogRum } from '@datadog/browser-rum';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';

const Price = ({ date, day, tooltipContent, printPrice, side }) => {
  const { price } = printPrice(
    {
      thousandPostfix: true,
      baseCurrency: date.minimumPrice.currencyCode,
      value: date.minimumPrice.valueIncludingTaxesAndFees,
    },
    true
  );

  // disable eslint rule here as we want to check if its exactly NaN
  // not just equivalent to NaN

  // eslint-disable-next-line use-isnan
  if (Number.isNaN(price)) {
    const error = new Error('Calendar price is NaN');
    console.error(error);
    datadogRum.addError(error);
    return null;
  }

  const id = `${side}-${day.format('DD')}-price`;
  return (
    <>
      <span className="minimum-price" id={id}>
        {price}
      </span>
      {!tooltipContent && (
        <UncontrolledTooltip
          placement="top"
          target={`day-${date?.date}`}
          style={{ textAlign: 'left' }}
        >
          {price}
        </UncontrolledTooltip>
      )}
    </>
  );
};

Price.propTypes = {
  date: PropTypes.object.isRequired,
  day: PropTypes.object.isRequired,
  tooltipContent: PropTypes.string,
  printPrice: PropTypes.func.isRequired,
  side: PropTypes.oneOf(['left', 'right']).isRequired,
};

export default Price;
