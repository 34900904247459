import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';

const PermanentModal = ({ children, isModal }) => {
  const containerStyles = useMemo(() => {
    return isModal
      ? {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
          overflow: 'hidden',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          overflowY: 'auto',
        }
      : {};
  }, [isModal]);

  const modalStyles = useMemo(() => {
    return isModal
      ? {
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          maxWidth: '650px',
          maxHeight: '750px',
          width: '90vw',
          height: '90vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxSizing: 'border-box',
          flexDirection: 'column',
        }
      : {};
  }, [isModal]);

  useEffect(() => {
    document.body.style.overflow = isModal ? 'hidden' : 'auto';
  }, [isModal]);

  return (
    <div style={containerStyles}>
      <div style={modalStyles}>{children}</div>
    </div>
  );
};

PermanentModal.propTypes = {
  children: PropTypes.node,
  isModal: PropTypes.bool,
};

export default PermanentModal;
