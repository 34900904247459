import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/icons/ArrowDownSoft.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/images/icons/ArrowUpSoft.svg';
import useLocalisedMoment from '../../hooks/useLocalisedMoment/useLocalisedMoment';
import usePrintPrice from '../../hooks/usePrintPrice/usePrintPrice';
import AccesibilityElement from '../AccesibilityElement/AccesibilityElement';
import styles from './NightlyRates.module.css';

const NightlyRates = ({ title, currencyCode, pricesPerNight, productCode }) => {
  const moment = useLocalisedMoment();
  const { printPrice } = usePrintPrice(productCode);
  const [nightCollapse, setNightCollapse] = useState(false);

  return (
    <div className="w-100">
      <AccesibilityElement
        className={styles.NightlyRates__nightlyRates}
        onClick={() => setNightCollapse((prev) => !prev)}
        tagName="div"
        style={{ cursor: 'pointer' }}
        ariaLabel="Open nightly rates"
      >
        <div>
          {parse(title)}
          {nightCollapse ? (
            <ArrowUpIcon style={{ marginLeft: 6 }} />
          ) : (
            <ArrowDownIcon style={{ margin: '2px 0 0 6px' }} />
          )}
        </div>
        <div style={{ fontWeight: 600 }}>
          {pricesPerNight?.length &&
            printPrice({
              value: pricesPerNight.reduce((acc, { price }) => acc + price, 0),
              baseCurrency: currencyCode,
            }).price}
        </div>
      </AccesibilityElement>
      {nightCollapse && (
        <div>
          <hr className={styles.NightlyRates__dottedHr} />
          {pricesPerNight.map(({ date, price }) => (
            <div
              className="d-flex justify-content-between mb-1"
              key={`${date}-${price}`}
            >
              <div>{moment(date).format('ddd, MMM D, YYYY')}</div>
              <div>
                {
                  printPrice({
                    value: price,
                    baseCurrency: currencyCode,
                  }).price
                }
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
NightlyRates.propTypes = {
  title: PropTypes.string,
  pricesPerNight: PropTypes.array,
  productCode: PropTypes.string,
  currencyCode: PropTypes.string.isRequired,
};

export default NightlyRates;
