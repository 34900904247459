const allowedDatadogErrors = [
  {
    // GSI FedCM errors
    condition: (error) =>
      error?.message?.includes('[GSI_LOGGER]: FedCM') &&
      error?.message?.includes('NetworkError: Error retrieving a token'),
    reason: 'GSI FedCM token retrieval error',
  },
  {
    // ResizeObserver loop errors
    condition: (error) =>
      error?.message?.includes(
        'ResizeObserver loop completed with undelivered notifications'
      ),
    reason: 'ResizeObserver notification loop error',
  },
];

export default allowedDatadogErrors;
