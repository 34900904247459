import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import BelmondSingleHotel from '../../components/BelmondSingleHotel/BelmondSingleHotel';
import { gtmPageView } from '../../gtm/events';
import getLowestHotelRates from '../../helpers/getLowestHotelRates/getLowestHotelRates';
import useAxios from '../../hooks/useAxios/useAxios';
import useLocalisedMoment from '../../hooks/useLocalisedMoment/useLocalisedMoment';
import usePrintPrice from '../../hooks/usePrintPrice/usePrintPrice';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import styles from './SelectHotel.module.css';

const MAX_FETCHES = 6;

const SelectHotel = () => {
  const axios = useAxios();
  const moment = useLocalisedMoment();
  const { t } = useTranslate();
  document.title = t('Select Hotel');

  const navigate = useNavigate();
  const { printPrice } = usePrintPrice();

  const [lowestRatePricePerHotel, setLowestRatePricePerHotel] = useState({});
  const [productLoading, setProductLoading] = useState({});

  const media = useSelector((state) => state.media.mediaUrls);

  const countryCode = useSelector((state) => state.appSettings.countryCode);

  const hotels = useSelector((state) => state.belmond.hotels);
  const hotelValues = useMemo(() => Object.values(hotels), [hotels]);

  const getHeroImage = (productCode) => {
    return media.find((mediaItem) =>
      mediaItem.includes(`HeroImages/${productCode}`)
    );
  };

  const fetchHotelRates = useCallback(
    async (enabledHotels, month) => {
      const batchSize = 10;
      const promises = [];

      for (let i = 0; i < enabledHotels.length; i += batchSize) {
        const hotelBatch = enabledHotels.slice(i, i + batchSize);
        promises.push(
          getLowestHotelRates(
            axios,
            moment,
            hotelBatch,
            month,
            printPrice,
            { adults: [2], children: [] },
            countryCode
          )
        );
      }

      try {
        const results = await Promise.all(promises);
        const mergedResults = results.reduce((acc, curr) => {
          return { ...acc, ...curr };
        }, {});

        setLowestRatePricePerHotel((prevRates) => ({
          ...prevRates,
          ...mergedResults,
        }));

        updateProductLoadingState(mergedResults);

        return mergedResults;
      } catch (e) {
        console.error(e);
        return {};
      }
    },

    // TODO: printPrice should be here, but it causes infinite loop, address ASAP
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, moment]
  );

  const updateProductLoadingState = (lowestRatePricePerHotelObj) => {
    setProductLoading((prevLoading) => {
      const newLoading = { ...prevLoading };
      Object.keys(lowestRatePricePerHotelObj).forEach((hotelCode) => {
        if (lowestRatePricePerHotelObj[hotelCode] !== undefined) {
          newLoading[hotelCode] = false;
        }
      });
      return newLoading;
    });
  };

  const fetchNextMonth = useCallback(
    async (hotels, month, currentFetchCount) => {
      const lowestRatePricePerHotelObj = await fetchHotelRates(hotels, month);
      const missingPropertyRates = hotels.filter(
        (hotelCode) => lowestRatePricePerHotelObj[hotelCode] === undefined
      );

      if (missingPropertyRates.length && currentFetchCount < MAX_FETCHES - 1) {
        const nextMonth = month.clone().add(1, 'month');
        fetchNextMonth(missingPropertyRates, nextMonth, currentFetchCount + 1);
      } else if (currentFetchCount === MAX_FETCHES - 1) {
        setProductLoading((prevLoading) => {
          const newLoading = { ...prevLoading };
          missingPropertyRates.forEach((hotelCode) => {
            newLoading[hotelCode] = false;
          });
          return newLoading;
        });
      }
    },
    [fetchHotelRates]
  );

  const handleBelmondSingleHotelClick = (productCode) => {
    navigate(`/select-room?productCode=${productCode}`);
  };

  useEffect(() => {
    const currentMonth = moment();

    const enabledHotels = hotelValues.map((hotel) => hotel.productCode);

    if (enabledHotels.length) {
      // Set initial loading state for all hotels
      setProductLoading(
        enabledHotels.reduce((acc, hotelCode) => {
          acc[hotelCode] = true;
          return acc;
        }, {})
      );

      fetchNextMonth(enabledHotels, currentMonth, 0);
    }
  }, [fetchHotelRates, fetchNextMonth, hotelValues, moment]);

  useEffect(() => {
    gtmPageView();
  }, []);

  return (
    <div className="container-xxl mb-4">
      <Row className="d-flex flex-row-reverse justify-content-center">
        <Col lg="12" className="mb-4">
          <div className={styles.SelectHotel__heading}>
            {t('Belmond Properties')}
          </div>
          <Row className="d-flex g-4 align-items-start">
            {hotelValues.length
              ? hotelValues.map((hotel) => (
                  <Col
                    xs="12"
                    sm="6"
                    lg="4"
                    className="d-flex"
                    key={hotel.productCode}
                  >
                    <BelmondSingleHotel
                      heroImg={getHeroImage(hotel.productCode)}
                      shortName={hotel.shortName}
                      region={hotel.region}
                      productCode={hotel.productCode}
                      onClick={handleBelmondSingleHotelClick}
                      maintenance={hotel.maintenance}
                      lowestRatePrice={
                        lowestRatePricePerHotel[hotel.productCode]
                      }
                      loading={productLoading[hotel.productCode]}
                    />
                  </Col>
                ))
              : [...Array(10)].map((_, i) => (
                  <Col xs="12" sm="6" lg="4" className="py-3" key={i}>
                    <Skeleton height={350} containerTestId="skeleton" />
                  </Col>
                ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SelectHotel;
