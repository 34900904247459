import 'react-dates-gte-react-17/lib/css/_datepicker.css';
import ReactDOM from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import 'symbol-observable';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './datadog';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import router from './router';
import './styles/index.css';
import './styles/react_dates_overrides.css';

// assign the app version to the window object
const APP_VERSION = process.env.REACT_APP_VERSION || 'local';
window.APP_VERSION = APP_VERSION;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RouterProvider router={router}></RouterProvider>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
