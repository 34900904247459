import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { ReactComponent as CrossIcon } from '../../assets/images/icons/Cross.svg';
import { ReactComponent as PresentIcon } from '../../assets/images/icons/Present.svg';
import calculateAddonPrice from '../../helpers/calculate-addon-price';
import useLocalisedMoment from '../../hooks/useLocalisedMoment/useLocalisedMoment';
import usePrintPrice from '../../hooks/usePrintPrice/usePrintPrice';
import useTranslate from '../../hooks/useTranslate/useTranslate';

function BookingsCartItemAddonsItem({
  addon,
  existing,
  removed,
  onRemove,
  booking,
}) {
  const {
    productCode,
    numAdults,
    children,
    startDate,
    endDate,
    roomRate: { currencyCode },
  } = booking;

  const moment = useLocalisedMoment();
  const { t } = useTranslate();
  const { printPrice } = usePrintPrice(productCode);
  const hotels = useSelector((state) => state.belmond.hotels);
  const [addonPrice, setAddonPrice] = useState(0);
  const hotel = hotels[productCode];

  const price = useMemo(() => {
    return calculateAddonPrice(
      addon,
      {
        numAdults,
        children,
        startDate,
        endDate,
      },
      moment
    );
  }, [addon, endDate, numAdults, children, startDate, moment]);

  const printPricePayload = useMemo(() => {
    return { value: Number(price) };
  }, [price]);

  useEffect(() => {
    // if a different currency code
    if (hotel.currency !== currencyCode) {
      if (!existing) {
        // if adding
        printPricePayload.toCurrency = currencyCode;
      } else {
        // if removing
        printPricePayload.baseCurrency = currencyCode;
      }
    }

    const { price } = printPrice(printPricePayload);
    setAddonPrice(price);
  }, [currencyCode, existing, hotel.currency, printPrice, printPricePayload]);

  return (
    <div className="d-flex flex-column my-4 w-100" key={addon.title}>
      <hr className="bbe-hr mb-4" />
      <div className="d-flex align-items-center">
        <PresentIcon style={{ marginRight: 15 }} />
        <div className="d-flex flex-row justify-content-between align-items-start w-100">
          <div style={removed ? { textDecoration: 'line-through' } : null}>
            <span style={{ fontWeight: 600 }}>{t('Extra')}</span> {addon.title}
          </div>

          {!removed && onRemove && (
            <Button
              className="textButton"
              onClick={onRemove}
              aria-label={`Remove ${addon.title}`}
            >
              <CrossIcon />
            </Button>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <div style={{ marginLeft: 35 }}>{t('Price')}</div>
        <div style={{ fontWeight: 600 }}>{addonPrice}</div>
      </div>
    </div>
  );
}

BookingsCartItemAddonsItem.propTypes = {
  addon: PropTypes.object,
  onRemove: PropTypes.func,
  removed: PropTypes.bool,
  existing: PropTypes.bool,
  booking: PropTypes.object.isRequired,
};

export default BookingsCartItemAddonsItem;
