const shouldFetchContent = (
  startDate,
  rateCode,
  productCode,
  endDate,
  moment
) => {
  if (moment(startDate).isBefore(moment(), 'day')) {
    return false;
  }
  return Boolean(rateCode && productCode && endDate);
};

export default shouldFetchContent;
