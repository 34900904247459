import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormGroup, Input, Label, Row } from 'reactstrap';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import styles from '../../GuestForm.module.css';

const GuestFormCheckButtons = ({ disabled, defaultMarketingOptinChecked }) => {
  const { t } = useTranslate();
  const form = useFormContext();

  return (
    <fieldset>
      <legend style={{ display: 'none' }}>{t('Acknowledgement')}</legend>
      <Row className="mt-4 mx-1">
        <div>
          <h2>{t('Acknowledgement')}</h2>
          <div className="mx-2">
            <FormGroup>
              <Label className={styles.GuestForm__formGroupCheckbox}>
                <Controller
                  name="isMarketingOptin"
                  control={form.control}
                  render={({ field }) => (
                    <Input
                      aria-label="isMarketingOptin"
                      className={styles.GuestForm__inputCheckbox}
                      type="checkbox"
                      {...field}
                      defaultChecked={defaultMarketingOptinChecked}
                      disabled={disabled}
                    />
                  )}
                />
                <div>
                  <span>
                    {t(
                      'I would like to be kept updated on exclusive news, travel inspiration and offers from the legendary world of Belmond, and receive tailored communications based on my interests and circumstances.'
                    )}{' '}
                    {t(
                      'To learn more about how we store and process your data please visit our'
                    )}{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://www.belmond.com/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('Privacy Policy')}
                    </a>
                    {'.'}
                  </span>
                </div>
              </Label>
            </FormGroup>
            <FormGroup>
              <Label className={styles.GuestForm__formGroupCheckbox}>
                <Controller
                  name="conditionsCheck"
                  control={form.control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="conditionsCheck"
                      aria-label="conditionsCheck"
                      type="checkbox"
                      className={styles.GuestForm__inputCheckbox}
                      {...field}
                      disabled={disabled}
                    />
                  )}
                />
                <span className={styles.GuestForm__requiredBlack}>*</span>
                {t('I agree with the Booking Conditions.')}
                <a
                  href="https://www.belmond.com/legal/hotels-terms-and-conditions"
                  rel="noreferrer"
                  target="_blank"
                  className={styles.GuestForm__checkboxLink}
                >
                  {t('Terms & Conditions')}
                </a>
              </Label>
            </FormGroup>
            {form.formState.errors.conditionsCheck?.type === 'required' && (
              <p className={styles.GuestForm__errorText}>
                {t('Error: Please agree to the Booking Conditions')}
              </p>
            )}

            {/* ONLY IN WECHAT */}
            {/* <>
              <FormGroup className="mt-4">
                <Label className={styles.GuestForm__formGroupCheckbox}>
                  <Controller
                    name="chinaGuaranteeCheck"
                    control={form.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="chinaGuaranteeCheck"
                        aria-label="chinaGuaranteeCheck"
                        type="checkbox"
                        className={styles.GuestForm__inputCheckbox}
                        {...field}
                        disabled={disabled}
                      />
                    )}
                  />
                  <span className={styles.GuestForm__requiredBlack}>*</span>
                  {t(
                    'I acknowledge that my requested room will only be guaranteed once Guarantee Link via email is completed.'
                  )}
                </Label>
              </FormGroup>
              {form.formState.errors.conditionsCheck?.type === 'required' && (
                <p className={styles.GuestForm__errorText}>
                  {t('Error: Please agree to the Booking Conditions')}
                </p>
              )}
            </> */}
          </div>
        </div>
        <div className="mt-4">
          <span>
            {t(
              "We collect and process your personal data when you click 'Confirm My Booking'."
            )}{' '}
            {t(
              'To see how Belmond stores and processes your personal data, and terms and conditions, please see our'
            )}{' '}
            <a
              style={{ color: 'black' }}
              href="https://www.belmond.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              {t('Privacy Policy')}
            </a>{' '}
            {t('and')} {t('the')}{' '}
            <a
              style={{ color: 'black' }}
              href="https://www.belmond.com/legal/hotels-terms-and-conditions"
              target="_blank"
              rel="noreferrer"
            >
              {t('Booking Conditions')}
            </a>
            {'.'}
          </span>
        </div>
      </Row>
    </fieldset>
  );
};

GuestFormCheckButtons.propTypes = {
  disabled: PropTypes.bool,
  defaultMarketingOptinChecked: PropTypes.bool,
};

export default GuestFormCheckButtons;
